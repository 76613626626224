import "./HeroImgStyles.css";

import React from 'react'

import IntroImg from "../assets/landing-page.png"

import { Link } from "react-router-dom"

const HeroImg = () => {
  return (
    <div className="hero">
        <div className="mask">
            <img className="intro-img"
            src={IntroImg} alt="IntroImg"/>
        </div>
        <div className="content">
            <p>Welcome! This is Oluwatofunmi Ilesanmi</p>
            <h1>I am an aspiring web Developer. I love to create and design fully responsive web applications.</h1>
            <div>
                <Link to="/project" 
                className="btn">Projects
                </Link>
                <Link to="/contact" 
                className="btn btn-light">Contact
                </Link>
            </div>
        </div>
    </div>
  )
}

export default HeroImg