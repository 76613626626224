import "./FooterStyles.css";

import React from 'react';

import { FaHome, FaPhone, FaMailBulk, FaGithub, FaLinkedin, FaGitlab } from "react-icons/fa";
import { SiCredly } from "react-icons/si";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        {/* Column 1: Name and Introduction */}
        <div className="left">
          <h4>Oluwatofunmi Ilesanmi</h4>
          <p>
            A creative software engineer and cloud developer. I love designing fully responsive Web applications.
          </p>
        </div>

        {/* Column 2: Contact Information */}
        <div className="middle">
          <h4>Contact Information</h4>
          <ul className="contact-list">
            <li><FaHome style={{ color: "#fff" }} />: Baltimore, Maryland</li>
            <li><FaPhone style={{ color: "#fff" }} />: 667-770-3870</li>
            <li><FaMailBulk style={{ color: "#fff" }} />: ilesanmi.tofunmi@gmail.com</li>
          </ul>
        </div>

        {/* Column 3: Social Links */}
        <div className="right">
          <h4>Social Links</h4>
          <div className="social">
            <p>
              Linkedin
              <a href="https://www.linkedin.com/in/oluwatofunmiilesanmi/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={20} style={{ color: "#fff", marginLeft: "0.5rem", verticalAlign: "middle" }} />
              </a>
            </p>
            <p>
              GitHub
              <a href="https://github.com/ilesanmi-tofunmi" target="_blank" rel="noopener noreferrer">
                <FaGithub size={20} style={{ color: "#fff", marginLeft: "0.5rem", verticalAlign: "middle" }} />
              </a>
            </p>
            <p>
              GitLab
              <a href="https://gitlab.com/i.tofunmi" target="_blank" rel="noopener noreferrer">
                <FaGitlab size={20} style={{ color: "#fff", marginLeft: "0.5rem", verticalAlign: "middle" }} />
              </a>
            </p>
            <p>
              Credly
              <a href="https://www.credly.com/users/oluwatofunmi-ilesanmi." target="_blank" rel="noopener noreferrer">
                <SiCredly size={20} style={{ color: "#fff", marginLeft: "0.5rem", verticalAlign: "middle" }} />
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="bottom-section">
        <p>© 2023 - Oluwatofunmi Ilesanmi</p>
      </div>
    </div>
  );
}

export default Footer;
