import React from 'react'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImg2 from '../components/HeroImg2'
import ResumeData from '../components/ResumeData'

const Resume = () => {
  return (
    <div>
        <Navbar/>
        <HeroImg2 heading="Click the button below to view my resume"/>
        <ResumeData />
        <Footer />
    </div>
  )
}

export default Resume