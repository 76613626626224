//import { Link } from "react-router-dom"
import "./AboutContentStyles.css"

import React from 'react'
import ResumePDF from "../assets/Resume.pdf";
import React1 from "../assets/about-image1.jpg"
import React2 from "../assets/about-image2.png"

const AboutContent = () => {
    const openResumeInNewTab = () => {
        window.open(ResumePDF, '_blank');
      };  
  return (
    <div className="about">
        <div className="left">
        <h1>Learn More About Me</h1>
            <p>I am an aspiring software engineer who recently graduated with a Master's degree in Computer Science from Towson University.</p>
            <p>With a strong foundation in data structures & algorithm, cloud architecture, and object-oriented programming, 
                I'm passionate about building fully responsive web applications and cloud-based solutions.</p>
            <p>As an AWS Certified Developer, I am proficient in leveraging cloud services to deploy robust applications.
                Some of my favorite technologies to work with are React, Angular, Node.js, Docker, and AWS Amplify, Lambda and more. I primarily code in Python, 
                Java, and Javascript. For all of my projects, I also write documentation and perform unit tests.</p>
            <p>A favorite motto of mine in life is "to never stop learning". 
                I am an analytical thinker and I love to bring abstract ideas to life.</p>
                <button className="btn" onClick={openResumeInNewTab}>View Resume</button>
        </div>
        <div className="right">
            <div className="img-container">
                <div className="img-stack top">
                   <img src={React1} className="img" 
                   alt="true"/> 
                </div>
                <div className="img-stack bottom">
                   <img src={React2} className="img" 
                   alt="true"/> 
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutContent