import "./HeroImg2Styles.css"

import React, { Component } from 'react'

import IntroImg2 from "../assets/background-project.png"

class HeroImg2 extends Component {
    render() {
        return (
            <div className="hero-img">
                <div className="heading">
                <img className="intro-img2"
                    src={IntroImg2} alt="IntroImg2"/>
                    <h1>{this.props.heading}</h1>
                    <p>{this.props.text}</p>
                </div>
            </div>
          )
    }
  
}

export default HeroImg2