import pro1 from "../assets/ReactEcommercestore-app-preview.png"
import pro2 from "../assets/hangman-react.png"
import pro3 from "../assets/memory-match.png"
import pro4 from "../assets/spray-paint.png"
import pro5 from "../assets/ReactTravelTourApp.png"

const ProjectCardData = [
    {
        imgsrc: pro1,
        title: "React and Stripe Shopping Cart Application",
        text: "A fully responsive Ecommerce store application built using React, Express.js and Stripe for payment processing. Utilized Heroku CLI to deploy the application. The application is designed to provide a seamless shopping experience with features such as responsive product display, dynamic cart management, and secure payment processing through Stripe",
        repository: "https://gitlab.com/tof-projects1/ecommercestore-with-reactandstripeapi",
        live: "https://react-ecommerce-store-aa648f5d3670.herokuapp.com/"
    },
    {
        imgsrc: pro2,
        title: "React Hangman Game",
        text: "A classic hangman game built using hooks in ReactJS and hooks. Deployed using AWS Amplify. The game involves guessing a word by selecting letters while avoiding incorrect guesses.",
        repository: "https://gitlab.com/i.tofunmi/react-hangman-game",
        live: "https://main.d24y4n52tk84xm.amplifyapp.com/"  
    },
    {
        imgsrc: pro3,
        title: "Flutter Memory Master Game",
        text: "An entertaining memory match game using Flutter and AWS Amplify, challenging players to uncover matching pairs. Authentication is added so players can create accounts and track their game scores. Utilized a GraphQL API to integrate with the Amplify backend. Leveraged GitHub Actions and Amplify Hosting for deployment.",
        repository: "https://github.com/ilesanmi-tofunmi/memory_match_game",
        live: "https://main.d2m0aznfokaecr.amplifyapp.com/"
    },
    {
        imgsrc: pro4,
        title: "WPF Image Spray Paint Application",
        text: "A Windows Presentation Foundation (WPF) desktop application designed for interactive image editing. The application provides users with the ability to load an image (in formats such as JPG, PNG, BMP, etc.) and perform spray painting on the image using the mouse. Built with C# and .NET Framework",
        repository: "https://github.com/ilesanmi-tofunmi/WPF-Spray-Paint-Application",
        live: "https://github.com/ilesanmi-tofunmi/WPF-Spray-Paint-Application"
    },
    {
        imgsrc: pro5,
        title: "Tour and Travel Website with ReactJS",
        text: "A fully responsive Tour and Travel Website built using ReactJS! This application is designed to create an engaging and dynamic website for a tour and travel agency called Tof-Trips",
        repository: "https://gitlab.com/i.tofunmi/tour-and-travel-website",
        live: "https://gitlab.com/i.tofunmi/tour-and-travel-website"
    },
];

export default ProjectCardData;

