import React from "react";
import "./ResumeData.css";
import ResumePDF from "../assets/Resume.pdf"; 

const ResumeData = () => {
  const openResumeInNewTab = () => {
    window.open(ResumePDF, '_blank');
  };
  return (
    <div className="about">
      <button className="btn" onClick={openResumeInNewTab}>View Resume</button>
    </div>
    
  );
};

export default ResumeData;
