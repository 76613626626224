import React from 'react'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImg2 from '../components/HeroImg2'
import Work from '../components/Work'

const Project = () => {
  return (
    <div>
        <Navbar/>
        <HeroImg2 heading="MY WORKS" 
        text="Below are some of the projects I've developed to sharpen my skills.
        From designing complex systems to optimizing user interfaces, 
        I've enjoyed the challenge of bringing ideas to life across a range of platforms and languages" />
        <Work />
        <Footer />
    </div>
  )
}

export default Project